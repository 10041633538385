<template>
  <div>
      <div class="all-but-footer">
      <router-view></router-view>
      </div>
      <Footer/>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer
  }
}
</script>
<style>
:root{
  --green: #289672;
  --green-dark: #1E6F5C;
  --green-lign: #29BB89;
  --wildcard: #E6DD3B;
  --file-color: #FFE896;
  --file-color-darker: #EAC547;
}

*{
  margin: 0;
  font-family: Arial;
}

.all-but-footer{
  background: url(../public/main-bg.png);
  background-size: 100% auto;
  min-height: calc(100vh - 64px);
}
</style>
