import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'About',
    component: function () {
      return import('../views/About.vue')
    }
  },
  {
    path: '/AgeOfWarRemake',
    name: 'AgeOfWarRemake',
    component: function () {
      return import('../views/AgeOfWarRemake.vue')
    }
  },
  {
    path: '/ExplosiveBet',
    name: 'ExplosiveBet',
    component: function () {
      return import('../views/ExplosiveBet.vue')
    }
  },
  {
    path: '/FTPKlient',
    name: 'FTPKlient',
    component: function () {
      return import('../views/FTPKlient.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
