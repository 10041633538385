<template>
  <div class="footer-wrapper">
    © Matěj Lajtkep 2021<br>
    <a class="footer-href" href="https://desu.lajtkep.dev">Stará verze webu</a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  }
}
</script>

<style scoped>
.footer-wrapper{
  padding-top: 20px;
  height: 44px;
  background: var(--green-dark);
  text-align: center;
}

.footer-href{
  text-decoration: none;
  color: black;
}
</style>
